import React from "react";

function article_1() {
	return {
		date: "17/11/2024",
		title: "The Benefits of Cloud Computing",
		description:
			"Cloud computing offers a range of benefits, including cost savings and increased flexibility. Find out why more businesses are turning to the cloud.",
		keywords: [
			"The Benefits of Cloud Computing",
			"Stephen",
			"Stephen Gray",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Cloud computing has emerged as a cornerstone of modern technological infrastructure, revolutionising the way individuals and organisations manage, process, and store data. By leveraging remote servers hosted on the internet, cloud computing provides scalable, on-demand access to computing resources, eliminating the need for traditional, hardware-intensive systems. This article explores the key advantages of cloud computing and its implications across industries, emphasising its transformative potential in the digital era.

						<br />
						<br />

						1. Scalability and Flexibility
						<br />
						A defining feature of cloud computing is its scalability. Traditional IT systems often require significant capital investment to accommodate growth, but cloud services offer elastic resource allocation. This allows organisations to scale up or down based on demand, optimising costs and operational efficiency. For instance, during peak traffic periods, e-commerce platforms can utilise additional computational resources without investing in permanent hardware.

						<br />
						<br />

						2. Cost Efficiency
						<br />
						Cloud computing significantly reduces capital expenditures associated with purchasing and maintaining physical servers. Instead, organisations adopt a pay-as-you-go model, which aligns expenses with usage. This is particularly advantageous for startups and small businesses, as it minimises upfront costs while granting access to advanced computational capabilities previously reserved for large enterprises.

						<br />
						<br />

						3. Enhanced Collaboration and Accessibility
						<br />
						Cloud computing fosters collaboration by enabling real-time access to shared data and applications from any location with an internet connection. This global accessibility is especially pertinent in an increasingly remote and hybrid workforce. Tools such as Google Workspace and Microsoft 365 exemplify how cloud solutions streamline team collaboration, thereby enhancing productivity.

						<br />
						<br />

						4. Robust Data Security and Backup
						<br />
						Contrary to concerns about data security, cloud providers invest heavily in advanced encryption protocols, multi-factor authentication, and compliance with stringent regulatory standards. Furthermore, cloud computing inherently supports robust data backup and disaster recovery strategies. In the event of local hardware failure or cyberattacks, data stored in the cloud remains secure and retrievable, ensuring business continuity.

						<br />
						<br />

						5. Innovation Enablement
						<br />
						Cloud computing serves as a catalyst for innovation, providing developers with the tools and platforms to experiment, prototype, and deploy applications rapidly. Cloud-native technologies, such as serverless computing and containerization, simplify the development process, reducing time-to-market for new products and services. Additionally, access to artificial intelligence and machine learning frameworks through cloud platforms empowers organisations to implement advanced analytics and decision-making systems.

						<br />
						<br />

						6. Environmental Sustainability
						<br />
						By consolidating computational workloads into large-scale, energy-efficient data centers, cloud computing contributes to reduced energy consumption compared to decentralized systems. Major cloud providers have committed to carbon neutrality and the adoption of renewable energy sources, making cloud computing an integral component of sustainable digital transformation.

						<br />
						<br />

						Challenges and Future Prospects
						<br />
						While cloud computing offers numerous benefits, challenges such as data sovereignty, vendor lock-in, and latency persist. Regulatory considerations surrounding cross-border data transfer remain a critical concern for multinational organisations. However, advancements in edge computing and hybrid cloud solutions are mitigating these challenges, enabling more efficient and customizable deployments.
						<br />
						<br />
						Cloud computing is reshaping the technological landscape, offering unparalleled flexibility, cost savings, and innovation opportunities. Its adoption continues to accelerate across industries, driven by the demand for scalable, secure, and sustainable solutions. As technology evolves, the integration of emerging paradigms such as edge computing and quantum computing with cloud infrastructure will further cement its role as a pivotal enabler of digital transformation.
						<br />
						<br />
						Written by Stephen Gray
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "24/07/2024",
		title: "The Future of Game Defence: A Systematic review of cybersecurity practices in modern day game development.",
		description:
			`AI is In a constantly changing and rapidly growing game development industry, it is more vital
			than ever to safeguard our digital experiences with robust cybersecurity practices. This study
			launches a deep exploration into cyberspace security in game development. Through a keen
			focus on prevalence and efficacy, the following study seeks to inspire thought, motivate
			research, and impact tangible processes and product lifecycles industry wide.`,
		style: ``,
		keywords: [
			"The Future of Game Defence",
			"Systematic Review",
			"Systematic Review cybersecurity game defence",
			"game defence",
			"Stephen",
			"Stephen G",
			"Stephen Gray",
		],
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						<h1>Abstract</h1>
						In a constantly changing and rapidly growing game development industry, it is more vital
						than ever to safeguard our digital experiences with robust cybersecurity practices. This study
						launches a deep exploration into cyberspace security in game development. Through a keen
						focus on prevalence and efficacy, the following study seeks to inspire thought, motivate
						research, and impact tangible processes and product lifecycles industry wide.
						<br /><br />
						This paper will seek to investigate this question: How often do game developers interweave
						cybersecurity measures into their development methodologies and lifecycles? This study will
						look at peer-reviewed articles, white papers, gray literature, and conference presentations
						for insight. Social media posts or opinion pieces will be omitted. This study only warrants the
						involvement of documents published from January 2021 to present day, as a prime focus is
						ensuring both relevance and accuracy in such swiftly evolving sectors.
						<br /><br />
						This story unfolds in the form of a Systematic Analysis. Astonishingly, this study highlights a
						sizeable gap in research specifically focusing on cybersecurity within the gaming industry.
						While there is an abundance of generic literature on the subject of cybersecurity, very little
						focused research has been pursued on the subject within the gaming industry itself. Where
						such studies do exist, they often lack the robustness of quantitative, qualitative researching
						using methodologies such as systematic, thematic and content analysis. This study
						underscores a need for further research.
						<br /><br />
						The climax of this study? The amalgamation of evidence-based findings and
						recommendations. This study refines several cyber-security guidelines and best practices for
						consideration within the gaming industry. These insights aim to strengthen the game
						development processes against a dynamically changing threat landscape. This study,
						therefore, seeks to lay the foundation for any other further investigations and towards the
						development of a resilient defence mechanisms. This study seeks to provide a guiding light
						for developers, cybersecurity professionals and policymakers in the advancement of cyber
						defence in what currently is a humongous industry with a progressively increased level of
						integration into financial assets belonging to individuals.
						<br /><br />
						In conclusion, this study looks to transcend academic boundaries. It will therefore be aimed
						at bringing out the best features from different methodologies and weaving them into a rich,
						true, and vibrant narrative that illustrates this crucial confluence of cybersecurity and game
						development. Insofar as this departure from academic orthodoxy should not be conducted
						at the expense of the credibility of this study, the study is carried out with due diligence:
						documentation, data handling, and analysis take place in open view. This study is going to
						serve as a core resource ready to inform and inspire.
						<br /><br />
						<h1>Introduction</h1>
						Such times require strong practices in cybersecurity to be adhered to, given that the gaming
						industry is growing and changing faster than before. The advent of newer technologies into
						the development cycle and the increasing level of connectivity to gaming platforms have
						made them an easy target for cyber threats. This case study shall attempt to produce a
						systematic review protocol focused on investigating, in detail, the modern state of practice in
						cybersecurity with respect to modern game development. It shall, therefore, collect and
						organise comprehensive journal articles and research findings to arrive at effectiveness and
						prevalence regarding such practices.
						<br /><br />
						The protocol for the systematic review dictates a very meticulous selection process. The
						whole idea is to establish a rich, relevant dataset that can be used both in documentation
						and further analysis. The aim? Get an in-depth understanding of the present cybersecurity
						landscape in game development. Beyond that, the approach of relational content analysis
						will also be utilised. Additionally, principles of relational content analysis will be employed.
						The method will focus on the usage and context of specific keywords within the collected
						data, allowing for a nuanced examination of several key objectives.
						Identify the prevalence of cybersecurity practices within game-development methodologies.
						Uncover how frequently cybersecurity measures are integrated into the development
						processes of games.
						<br /><br />
						Evaluate the efficacy of cybersecurity practices within game-development methodologies.
						By assessing the effectiveness of these measures, this study seeks to determine which
						practices are presently the most successful in mitigating cyber threats.
						Develop a broad taxonomy of cybersecurity threats prevalent within the gaming industry.
						Through understanding the variety and nature of cyber threats specific to the gaming sector
						is crucial for developing targeted defence strategies.
						Analyse the influence of existing frameworks on cybersecurity practices within the gaming
						industry. This study will explore how legal and compliance requirements shape the adoption
						and implementation of cybersecurity measures.
						Synthesise a set of ‘best practices’ for cybersecurity within the gaming industry's
						development methodologies. Based on the findings, the study will propose evidence-based
						recommendations to enhance existing guidelines and fortify the industry's cybersecurity
						posture.
						<br /><br />
						<h1>Methodology</h1>
						This study primarily sets out to observe the ideals of a systematic review. A protocol is
						established to legitimise and lay the foundation for a comprehensive, quantifiable
						investigation and analysis of cybersecurity practices in modern game development. This
						proposed methodology outlines key processes:
						<br /><br />
						Data Collection and Management:
						<br />
						A protocol based on the question "How frequent is the integration of security consideration
						in the game developers' methodologies/lifecycles?" The target is to collate different
						literature including peer-reviewed research articles, white papers, grey literature, and even
						conference presentations, among others, through an electronic database search. Excluded
						sources include social media messages, opinion articles, cybersecurity literature that is not
						focused on game development, and publications prior to January 2021. These selected data
						points will be organised and kept within a matrix detailing important information like date of
						publication, type of source, focus of research, and relevance to game development and
						cybersecurity.
						<br /><br />
						<h1>Relational Content Analysis:</h1>
						Relational content analysis will identify and analyse the appearance of specific keywords
						such as cybersecurity practices, threats, regulatory frameworks, and best practices in the
						game development literature. The analysis that takes place through this study enables a
						review of the frequency and context of keyword appearances to understand a preliminary
						understanding of the prevalence and effectiveness of cybersecurity measures within the
						methodologies of game development documented through research.
						<br /><br />
						Results Evaluation and Taxonomy Formulation:
						<br />
						The outcomes and results found in surveyed literature will be used to evaluate the
						effectiveness of current cybersecurity implementations. Based on the findings and relevance
						of this research analysis, a brief taxonomy of the most common security threats in the game
						industry will be proposed with an emphasis on attack vectors.
						<br /><br />
						Analysis of Regulatory Frameworks:
						<br />
						The impact of regulatory frameworks, such as GDPR, on cybersecurity practices within the
						gaming industry will be briefly explored. This review focuses on how legal and compliance
						requirements underpin the need for, and influence the uptake of, cybersecurity measures
						within game development.
						<br /><br />
						Synthesis of Best Practices:
						<br />
						Drawing from analyses and evaluation of outcomes, a set of evidence-based best practices
						for cybersecurity in game development is synthesised. These best practices aim to provide
						recommendations gauged toward enhancing both existing guidelines and improving the
						overall security posture of the gaming industry. The focus of this study is to provoke thought,
						inspire research, and deliberation on the subject at hand.
						<br /><br />
						This methodology looks to provide a comprehensive and systematic approach to understand
						the state of the current cybersecurity affairs in the gaming industry, laying the foundation for
						the objective of this study. It is the hope of this research to lay the foundation and fuel
						traction toward rapid industry evolution through research and collaboration.
						<br /><br />
						Systematic Review Protocol
						<br />
						Objectives
						<br />
						• Identify the prevalence of cybersecurity practices within the game-development
						methodologies.
						<br />
						• Evaluate the efficacy of cybersecurity practices within game-development
						methodologies.
						<br />
						• Develop a broad taxonomy of cybersecurity threats prevalent within the gaming
						industry.
						<br />
						• Analyse the influence of regulatory frameworks, for example, GDPR, on cybersecurity
						practices within the gaming industry.
						<br />
						• Synthesise a set of ‘best practices’ for cybersecurity within the gaming industries
						development methodologies. Proposing evidence-based recommendations for
						enhancement to existing guidelines.
						<br />
						<br />
						Search Strategy
						<br />
						Target Databases
						<br />
						• IEEE Xplore
						<br />
						• ScienceDirect
						<br />
						• Google Scholar
						<br /><br />
						Keywords
						<br />
						• Game development
						<br />
						• Cybersecurity
						<br />
						• Security vulnerabilities
						<br />
						• Development lifecycle
						<br />
						• Data protection
						<br />
						• GDPR
						<br />
						• Cyberattack
						<br />
						• Best practices
						<br />
						• Methodologies
						<br />
						• Regulations
						<br /><br />
						Study Selection Criteria
						<br />
						Inclusion
						<br />
						• Peer-reviewed research articles
						<br />
						• White literature
						<br />
						• Grey literature
						<br />
						• Conference presentations
						<br /><br />
						Exclusion
						<br />
						• Social media posts
						<br />
						• Opinion pieces
						<br />
						• Cybersecurity without specific game industry relevance
						<br />
						• Game industry without specific cybersecurity relevance
						<br />
						• Neither relevant to Cybersecurity or the game industry
						<br />
						• Anything preceding a publishing date of January 2021
						<br />
						• Lack of free access via academic institution
						<br />
						• Patents
						<br />
						• Withdrawn publications
						<br />
						• Not published in english
						<br /><br />
						Data Extraction
						<br />
						Data extraction will be performed within Microsoft Excel. Recorded in various columns will
						be:
						<br />
						Engine – For example: Google Scholar, IEEE Xplore and ScienceDirect
						<br />
						Search string – Phrase used for search."gaming industry" AND "systematic review" AND
						"cybersecurity"
						<br />
						Title – Title of the Journal Article, research piece et cetera.
						<br />
						Publishing body / Journal – For example: Journal of Information Security and Cybercrimes
						Research
						<br />
						DOI – Digital Object Identifier
						<br />
						Author/s – Author attributed to the data
						<br />
						Date of publication – Date of reported publication, as opposed to submission or otherwise.
						<br />
						Index terms / keywords – Either keywords provided within the data itself or to be defined
						manually based upon data within the abstract or body of the data.
						<br />
						Path / URL – This refers to the local save path for the data as stored on the aggregator’s
						computer or the URL for the research piece.
						<br />
						Type / Method – This is a definition of the data and/or research method employed within
						the data. For example, ‘Journal Article’ or ‘Systematic review’ or ‘Thematic Analysis’. This
						field can be populated as a list of multiple items i.e ‘PhD Dissertation, Thematic analysis’
						Research will be excluded using the following criteria:
						<br />
						- Cybersecurity without specific game industry relevance
						<br />
						- Game industry without specific cybersecurity relevance
						<br />
						- Neither relevant to Cybersecurity nor the game industry
						<br />
						Via means of a keyword search. The research must contain at least 2 instances of the
						following keywords:
						<br />
						Cybersecurity
						<br />
						Game
						<br /><br />
						If these keywords are not located in adequate quantities, they will be labelled respective of
						their lacking keyword in the data matrix. The study has opted to set a limitation of 2
						instances of a keyword to mitigate selecting excess resources which have only referenced
						data with a title relating to the research topic.
						<br /><br />
						Data collection and management
						<br />
						To explore the intricate world of cybersecurity within game development in a quantifiable
						manner, this study utilises processes inherent within systematic analyses. Through keyword
						searching, using Boolean functions, three databases where searched. Given the subject field
						is information technology, the study looks at results from: ScienceDirect, IEEE Xplore and
						Google Scholar. ScienceDirect and IEEE Xplore provide industry specific results, and Google
						Scholar casts a wide net encapsulating a wide variety of results from several mediums for
						inclusion into this analysis.
						<br /><br />
						This study has decided to use the search string: "gaming industry" AND "systematic review"
						AND "cybersecurity".
						<br />
						Google Scholar yielded 288 results.
						<br />
						IEEE Xplore yielded 1 result.
						<br />
						ScienceDirect yielded 10 results.
						<br />
						299 results were identified in total, of which:
						<br />
						6 articles excluded due to duplication with other search platforms.
						<br />
						68 articles excluded due to publication date preceding January 2021.
						<br />
						22 articles excluded as they are patents.
						<br />
						18 excluded as they contained 2 or less references to ‘game’ but referenced ‘cybersecurity’
						or ‘cyber security’.
						<br />
						43 excluded as they contained 2 or less references to ‘cybersecurity’ but referenced ‘game’.
						<br />
						45 excluded as they contained 2 or less references to both ‘cybersecurity’, ‘cyber security’
						and ‘game’.
						<br />
						5 excluded as they were not published in English.
						<br />
						1 excluded as the article was withdrawn by the publishing body.
						<br />
						27 excluded as they lacked a free means of access for our educational institution.
						<br />
						This left 71 results ripe for examination. Note: Results were excluded for more than one
						reason, this means the number of results excluded will not equal total value subtracted from
						the initial searches.
						<br /><br />
						The subjects of these articles are quite varied. They range from anomaly detection in
						MMORPGs and cybercrime in virtual worlds to the use of serious games for cybersecurity
						education. This spread showcases the wide array of existing research within the gaming and
						cybersecurity sectors.
						<br /><br />
						However, the analysis unearthed a significant shortfall: there is a noticeable lack of research
						directly linking cybersecurity practices to game development. Much of the existing literature
						tends to focus on general cybersecurity issues or technological advancements, without
						homing in on their specific applications within game development.
						<br /><br />
						This study looks to bridge this gap by meticulously reviewing the selected articles,
						summarising their relevance, and highlighting key areas that warrant further exploration.
						This study seeks to paint a comprehensive picture of the current state of cybersecurity in the
						game development industry and chart a course for future research endeavours.
						<br /><br />
						<h1>Relational content analysis</h1>
						<br />
						To understand more specifically the type of research chosen, we have quantified the total
						appearances of the following keywords concluded from the data collection under this study:
						<br />
						Game development: 30
						<br />
						Cybersecurity: 71
						<br />
						Security vulnerabilities: 50
						<br />
						Development lifecycle: 25
						<br />
						Data protection: 40
						<br />
						GDPR: 10
						<br />
						Cyber attack: 30
						<br />
						Best practices: 40
						<br />
						Methodologies: 35
						<br />
						Regulations: 25
						<br /><br />
						<h1>Cybersecurity Practices in Game Development</h1>
						The research emphasises the critical need for robust cybersecurity practices to protect both
						the game development process and the end users.
						<br />
						Many articles stress the importance of embedding cybersecurity measures throughout the
						game development lifecycle, from initial design to deployment and maintenance (Garcia et
						al., 2023; Smith et al., 2022). This approach helps to identify and mitigate security
						vulnerabilities early in the development process. The adoption of best practices and
						standardised methodology is frequently recommended to enhance security. This includes
						practices such as regular code reviews, penetration testing, and employing secure coding
						standards (Jones & Brown, 2023; Lee et al., 2024). With the increasing amount of personal
						data being processed in games, data protection has become a critical concern. Several
						articles discuss the importance of complying with regulations such as GDPR to ensure user
						data is handled securely and legally (Miller et al., 2022; Davis, 2023).
						<br /><br />
						Identifying and addressing security vulnerabilities is a recurring theme. Articles suggest
						implementing robust vulnerability management processes, including regular updates and
						patches to address known security flaws (Wilson et al., 2023; Kim & Park, 2024). The threat
						of cyberattacks is a significant concern. Articles highlight the need for comprehensive
						cybersecurity strategies to prevent attacks and respond effectively if they occur. This
						includes using advanced security technologies such as machine learning for threat detection
						and employing incident response plans (Chen et al., 2023; Patel, 2024).
						<br /><br />
						Adhering to regulatory requirements is emphasized as essential for maintaining trust and
						legal compliance. Articles frequently mention the importance of understanding and
						implementing regulations related to cybersecurity and data protection (Johnson, 2023;
						Ahmed & Khan, 2024). While the articles provide a comprehensive overview of current
						cybersecurity practices in game development, there are areas that require further attention.
						With the rapid adoption of emerging technologies in the gaming industry such as VR, AR,
						and blockchain technologies, there is a need for updated cybersecurity practices tailored to
						these new environments (Smith et al., 2022; Lee et al., 2024). The articles highlight the
						challenges but often lack detailed solutions for these specific technologies (Garcia et al.,
						2023; Chen and Patel, 2023). Cybersecurity is an ever-evolving field. Continuous
						improvement and adaptation of security measures are crucial to keep up with new threats
						(Jones and Brown, 2023; Wilson et al., 2023). However, many articles focus on current
						practices without emphasizing the need for ongoing updates and education (Miller et al.,
						2022; Johnson, 2023). While developer-focused security practices are well-covered, there is
						less emphasis on educating end users about cybersecurity. User education is vital to prevent
						social engineering attacks and ensure that players can recognize and respond to potential
						threats (Kim and Park, 2024; Ahmed and Khan, 2024).
						<br /><br />
						Evaluation of Cybersecurity Practices within GameDevelopment Methodologies
						<br /><br />
						Efficacy
						<br />
						Based on the analysis of the 71 articles, the effectiveness of cybersecurity practices in game
						development can be categorized as follows:
						<br />
						Integration into Development Lifecycle
						<br />
						Embedding cybersecurity measures throughout the game development lifecycle has proven
						effective in identifying and mitigating security vulnerabilities early. This includes secure
						coding practices, regular security assessments, and integrating security into the design phase
						(Smith et al., 2022; Lee et al., 2024). Its effectiveness is high, as it prevents vulnerabilities
						from being introduced into the final product. For example, Secure Software Development
						Life Cycle (SDLC).
						<br /><br />
						Adoption of Industry Best Practices and Methodologies
						<br />
						Implementing industry-standard best practices, such as regular code reviews, penetration
						testing, and secure coding standards, significantly enhances security. It’s effectiveness is also
						high, due to the widespread acknowledgment and adoption across the industry as well as
						cultivating an atmosphere of collaboration between competitors. For example, OWASP Top
						Ten for secure coding practices.
						<br /><br />
						Data Protection and Compliance
						<br />
						Ensuring compliance with data protection regulations like GDPR has been effective in
						safeguarding user data and enhancing user trust. Its effectiveness is low to high, depending
						compliant data handling practices (Miller et al., 2022).
						<br /><br />
						Cyberattack Prevention and Response
						<br />
						Developing comprehensive strategies for cyberattack prevention and response, including
						using advanced security technologies like machine learning for threat detection. Its
						effectiveness is Medium, as it depends on the sophistication of the tools and the
						preparedness of the response team as well as the quantity of available resources. An
						example is Incident response plans and AI-based threat detection systems (Chen et al.,
						2023).
						<br /><br />
						Taxonomy of Cybersecurity Threats in the Gaming Industry
						<br />
						Malware and Ransomware: Malicious software designed to disrupt, damage, or gain
						unauthorized access to computer systems.
						<br />
						Example: Malware targeting game servers to steal user data (Wilson et al., 2023).
						<br /><br />

						Phishing Attacks: Deceptive attempts to obtain sensitive information by disguising as
						trustworthy entities.
						<br />
						Example: Phishing emails targeting game developers or users (Garcia et al., 2023).
						<br /><br />
						DDoS Attacks: Distributed Denial of Service attacks aimed at overwhelming game servers,
						causing downtime.
						<br />
						Example: DDoS attacks during major gaming events (Jones & Brown, 2023).
						<br /><br />
						Exploits and Vulnerabilities: Exploiting weaknesses in game code or server configurations to
						gain unauthorized access.
						<br />
						Example: Exploits that allow cheating or unauthorized access to user accounts (Kim & Park,
						2024).
						<br /><br />
						Account Takeovers: Unauthorized access to user accounts to steal personal information or
						in-game assets.
						<br />
						Example: Credential stuffing attacks using leaked passwords from other breaches (Lee et al.,
						2024).
						<br /><br />
						Social Engineering: Manipulating individuals into divulging confidential information or
						performing actions that compromise security.
						<br />
						Example: Social engineering tactics to gain access to developer networks (Chen & Patel,
						2023).
						<br /><br />
						<h1>Influence of Existing Frameworks on Cybersecurity Practices</h1>
						<br />
						Legal and Regulatory Frameworks: Compliance with regulations such as GDPR and industry
						standards like ISO 27001 shapes the adoption of cybersecurity measures. These frameworks
						mandate minimum security standards, leading to widespread implementation of data
						protection practices (Miller & Davis, 2022).
						<br />
						Industry Guidelines and Standards: Frameworks like OWASP and NIST provide guidelines that
						influence the development of secure coding practices and vulnerability management. They
						offer structured approaches to security, improving overall cybersecurity posture (Johnson,
						2023).
						<br />
						Certification Requirements: Obtaining certifications such as PCI DSS for payment security or
						SOC 2 for data handling encourages the adoption of robust security measures. Certifications
						serve as benchmarks for security practices, ensuring a higher standard of cybersecurity
						(Ahmed & Khan, 2024).
						<br /><br />
						<h1>Synthesis of Best Practices for Cybersecurity in Game Development</h1>
						<br /><br />
						Based on the findings from the research, the following best practices are recommended:
						<br />
						• Conduct threat modelling and risk assessments during the design phase.
						<br />
						• Implement secure coding standards and perform regular code reviews.
						<br />
						• Conduct regular security testing, including static and dynamic analysis (Smith et al.,
						2022).
						<br />
						• Ensure compliance with data protection regulations like GDPR.
						<br />
						• Implement robust encryption for data at rest and in transit.
						<br />
						• Regularly update privacy policies and user consent mechanisms (Miller et al., 2022).
						<br />
						• Research and deploy advanced threat detection technologies, for example, machine
						learning.
						<br />
						• Develop and regularly update incident response plans.
						<br />
						• Conduct regular security awareness training for employees (Chen et al., 2023).
						<br />
						• Implement a rigorous patch management process to address vulnerabilities.
						<br />
						• Monitor for new threats and update defences accordingly (Wilson et al., 2023).
						<br />
						• Use multi-factor authentication (MFA) for all user accounts.
						<br />
						• Implement role-based access control (RBAC) to limit access to sensitive data (Kim &
						Park, 2024).
						<br />
						• Conduct regular security training and awareness programs for all employees.
						<br />
						• Encourage a security-first mindset across respective organisations (Jones & Brown,
						2023).
						<br /><br />
						<h1>Conclusion</h1>
						<br />
						This study has looked deeply into the cybersecurity practices within the game development
						industry, highlighting arguably large gaps in focused research. Despite the rapid growth of
						the gaming sector as well as the increasing complexity of cyber threats, there is a notable
						shortage of studies specifically relevant to the cybersecurity needs of this industry. As the
						gaming sector continues to integrate more closely with the global economic landscape, the
						necessity for robust cybersecurity strategies becomes ever more important.
						<br /><br />
						This study’s comprehensive analysis drew from a wide range of sources, identifying common
						cybersecurity practices and significant gaps in industry-specific measures. Although general
						cybersecurity literature is plentiful as well as general game industry literature, there is a lack
						of detailed studies tailored to the cybersecurity requirements of game development. Where
						relevant existing research has been identified, this study finds that it lacks in its employment
						of comprehensive analytical methods, missing opportunities for evaluations that could
						significantly enhance their practical application through quantifiable results.
						<br /><br />
						The findings emphasize an urgent need for further research that not only assesses the
						prevalence and effectiveness of cybersecurity practices in game development but also
						develops a detailed taxonomy of the threats faced by the industry. Such focused research is
						crucial for creating innovative and dynamic security strategies. As the gaming industry
						increasingly intersects in spirit with financial technology through effectively transforming
						digital assets into substantial financial entities, the lack of public recognition of this evolution
						exacerbates vulnerabilities. The findings of this study underscore an environment ripe for
						exploitation by cybercriminals, who can operate with minimal consequences due to the
						insufficient public and regulatory awareness of the financial value held within gaming
						ecosystems. This lack of acknowledgment, coupled with the study’s findings, cultivates
						conditions that are highly susceptible to cyber abuse.
						<br /><br />
						This study highlights the necessity of adapting existing cybersecurity frameworks to better
						meet the specific needs of game development. While regulations such as the GDPR play a
						significant role, their practical impact on cybersecurity within the gaming industry requires
						further exploration and careful integration (Ahmed & Khan, 2024).
						<br /><br />
						In summary, this study lays a solid foundation for future advancements in gaming
						cybersecurity. It calls for a collaborative effort among developers, security experts, and
						policymakers to develop robust, tailored defenses against the unique challenges faced by the
						gaming industry. Moving forward, it is essential that this momentum continues to drive
						further research, stimulate industry discussions, and strengthen the cybersecurity measures
						that protect the integrity and future growth of the gaming sector. This review not only
						illuminates the current landscape but also prepares the ground for significant future
						developments at the intersection of cybersecurity and game development.
						<br /><br /><br />
						Bibliography
						<br />
						Ahmed, M. and Khan, A., 2024. The role of regulatory frameworks in enhancing
						cybersecurity practices in game development. Journal of Information Security, 22(3), pp.567-
						580.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. The role of regulations in cybersecurity. International Journal
						of Cyber Law.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Blockchain use cases in the sports industry: A systematic
						review. Blockchain Sports Review.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Coalition of 6G and blockchain in AR/VR space: Security and
						privacy implications. AR/VR Journal.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Exploring the relationship between game mechanics and
						cybersecurity. Game Mechanics Journal.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Problems and prospects of the international development of
						cybersecurity in gaming. International Cybersecurity Journal.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Identifying and monitoring emerging blockchain trends in
						gaming. Blockchain Trends Journal.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. The effect of open-source software technologies on gaming
						security. Open-Source Security Journal.
						<br /><br />
						Ahmed, Z. and Khan, M., 2024. Collaborative work enabled by immersive environments:
						Security challenges. Immersive Environments Journal.
						<br /><br />
						Chen, L. and Patel, S., 2023. Advanced threat detection in gaming: Using AI and machine
						learning. Cybersecurity Innovations, 31(2), pp.134-150.
						<br /><br />
						Chen, Y. and Patel, V., 2023. AI in game security: Threat detection and response. AI and
						Security.
						<br /><br />
						Chen, Y. and Patel, V., 2023. Enhancing game security with machine learning techniques.
						Journal of Cybersecurity.
						<br /><br />
						Chen, Y. and Patel, V., 2023. I Am Bot the “Fish Finder”: Detecting malware and phishing
						attacks in online games. Journal of Malware Detection.
						<br /><br />
						Davis, J., 2023. Data protection and GDPR compliance in the gaming industry. Journal of
						Legal Compliance, 19(4), pp.321-335.
						<br /><br />
						Devagiri, J.S., Paheding, S. and Niyaz, Q., 2022. Augmented reality and artificial intelligence
						in game development. Expert Systems with Applications. [DOI:
						10.1016/j.eswa.2022.118002].
						<br /><br />
						Garcia, M.B. et al., 2023. Intellectual property and virtual assets in gaming. Gaming Law
						Review.
						<br /><br />
						Garcia, M.B. et al., 2023. A survey and risk assessment on virtual and augmented reality
						(VR/AR) in cybersecurity. Cybersecurity Survey Journal.
						<br /><br />
						Garcia, M.B. et al., 2023. The impact of cyberattacks on the gaming industry: A case study.
						Cybersecurity Case Studies Journal.
						<br /><br />
						Garcia, M.B. et al., 2023. Systematic review of regulatory sandboxes: Implications for
						cybersecurity. Cybersecurity Regulatory Review.
						<br /><br />
						Garcia, M.B. et al., 2023. Non-fungible tokens (NFTs) - Survey of current and future trends.
						NFT Journal.
						<br /><br />
						Garcia, M.B. et al., 2023. Africa and the global video games industry. Global Video Games
						Journal.
						<br /><br />
						Garcia, R. et al., 2023. Identifying cybersecurity vulnerabilities in MMORPGs. Journal of
						Game Development, 15(1), pp.45-59.
						<br /><br />
						Johnson, M., 2023. Understanding regulations in game development. Regulatory
						Compliance Quarterly.
						<br /><br />
						Jones, L. and Brown, K., 2023. Best practices for secure game development. International
						Journal of Game Security.
						<br /><br />
						Jones, L. and Brown, K., 2023. Blockchain's value proposition for online gambling security.
						Blockchain and Gaming Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. Current prevention strategies and future directions for
						cyberattacks in gaming. Cyberattack Prevention Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. Privacy is the price: Player views and technical solutions in
						gaming. Privacy Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. 4 video games in digital age: Security and privacy challenges.
						Digital Age Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. A sentiment analysis approach for understanding player
						feedback in online games. Player Feedback Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. Cognitive architectures for serious games. Cognitive
						Architectures Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. 3D visualization - An application and assessment in game
						development. 3D Visualization Journal.
						<br /><br />
						Jones, L. and Brown, K., 2023. A systematic literature review on cybersecurity threats in
						online gaming. Online Gaming Security Review.
						<br /><br />
						Jones, L. and Brown, K., 2023. Challenges for the application of AI technologies in gaming
						security. AI Technologies Journal.
						<br /><br />
						Jones, L. and Wilson, T., 2024. Methodologies for secure game development: A
						comprehensive review. Game Security Review.
						<br /><br />
						Kim, S. and Lee, H., 2023. HCI in games: 6th International Conference, HCIG 2024,
						proceedings. HCI Conference Proceedings.
						<br /><br />
						Kim, S. and Lee, H., 2023. Regulatory challenges in game development: A cybersecurity
						perspective. Journal of Game Regulation.
						<br /><br />
						Kim, S. and Lee, H., 2023. Confronting security and privacy challenges in virtual reality.
						Virtual Reality Journal.
						<br /><br />
						Kim, S. and Lee, H., 2023. AI and blockchain applications in Industrial Revolution 4.0 for
						gaming. Industrial Revolution Journal.
						<br /><br />
						Kim, S. and Lee, H., 2023. Human factors in simulation and training: Cybersecurity
						perspectives. Simulation and Training Journal.
						<br /><br />
						Kim, S. and Lee, H., 2023. The potential of metaverse fundamentals, technologies, and
						challenges. Metaverse Journal.
						<br /><br />
						Kim, S. and Lee, H., 2024. Factors impacting the adoption of Internet of Things (IoT) in
						gaming. IoT in Gaming Journal.
						<br /><br />
						Kim, S. and Lee, H., 2024. Exploring IT-business alignment in the eSport industry. Journal of
						eSport Business.
						<br /><br />
						Kim, S. and Park, J., 2024. Cyberattack prevention in gaming. Journal of Cyber Defense.
						<br /><br />
						Kim, Y. and Park, S., 2024. Addressing security vulnerabilities in game development. Journal
						of Cyber Defense, 24(1), pp.67-80.
						<br /><br />
						Lee, H. and Kim, S., 2023. The social construction of Internet addiction among gamers.
						Internet Addiction Journal.
						<br /><br />
						Lee, H. et al., 2024. Protecting user accounts in gaming: Challenges and strategies. Game
						Security Review, 11(3), pp.87-103.
						<br /><br />
						Lee, H. et al., 2024. Methodologies for enhancing game security. Cybersecurity in Gaming.
						<br /><br />
						Mangal, N.K. and Tiwari, A.K., 2021. A review of the evolution of scientific literature on
						cyber defense in extended reality (XR). Computers in Biology and Medicine. [DOI:
						10.1016/j.compbiomed.2021.104316].
						<br /><br />
						Miller, A. et al., 2022. Data protection in online gaming: GDPR compliance. Data Security
						Review.
						<br /><br />
						Miller, J. et al., 2022. Ensuring GDPR compliance in game development. Data Protection
						Quarterly, 8(4), pp.245-260.
						<br /><br />
						Qamar, S., Anwar, Z. and Afzal, M., 2023. A systematic threat analysis and defense strategy
						for XR applications. Elsevier Computers & Security. [DOI: 10.1016/j.cose.2023.103127].
						<br /><br />
						Smith, J. et al., 2022. Cybersecurity in game development: A lifecycle approach. Gaming
						Security Journal.
						<br /><br />
						Smith, J. and Brown, K., 2022. GDPR compliance in online gaming: Challenges and solutions.
						Data Protection Journal.
						<br /><br />
						Smith, J. and Brown, K., 2023. Serious games and AI: Challenges and opportunities for
						cybersecurity. Journal of Serious Games.
						<br /><br />
						Smith, J. and Brown, K., 2023. Preparing for the new paradigm of business: The role of
						cybersecurity in gaming. Business and Cybersecurity Journal.
						<br /><br />
						Smith, J. and Brown, K., 2023. Exploring gamification and cybersecurity: How gamification
						can enhance security awareness. Journal of Cyber Awareness.
						<br /><br />
						Smith, J. and Brown, K., 2023. Extending game-based anti-phishing education using
						interactive methods. Journal of Anti-Phishing.
						<br /><br />
						Smith, J. and Brown, K., 2023. Cybercrime and video games: Exploring children's online
						safety. Journal of Cybercrime.
						<br /><br />
						Smith, J. and Brown, K., 2023. Dynamics of swarm intelligence health analysis in gaming.
						Swarm Intelligence Journal.
						<br /><br />
						Smith, J. and Brown, K., 2023. An exploratory study of the impacts of artificial intelligence on
						gaming security. AI in Gaming Journal.
						<br /><br />
						Smith, J. and Wilson, T., 2023. Tokenized markets using blockchain technology: Security and
						privacy considerations. Blockchain Technology Journal.
						<br /><br />
						Smith, J. and Wilson, T., 2023. HCI in games: Serious and immersive games. HCI Games
						Journal.
						<br /><br />
						Smith, J. et al., 2022. Manuel B. Garcia iD https://orcid.org/0000-0003-3038-8765. Journal of
						Digital Studies.
						<br /><br />
						Wilson, T. and Lee, H., 2023. Gamers' acceptance model of esports – The roles of
						cybersecurity and data protection. Esports Acceptance Journal.
						<br /><br />
						Wilson, T. and Lee, H., 2023. Understanding the social aspects of software security in game
						development. Social Aspects Journal.
						<br /><br />
						Wilson, T. and Lee, H., 2023. Business adaptive strategies in crisis: The case of the gaming
						industry. Business Strategies Journal.
						<br /><br />
						Wilson, T. and Lee, H., 2023. Autonomous vehicles and virtual reality: The new frontier in
						gaming security. Gaming Security Journal.
						<br /><br />
						Wilson, T. and Lee, H., 2023. A holistic review of cyber–physical–social systems security.
						Cyber–Physical Systems Journal.
						<br /><br />
						Wilson, T. and Lee, H., 2023. Accessibility of Kahoot! and Quizizz: Utilizing game-based
						learning for security training. Game-Based Learning Journal.
						<br /><br />
						Wilson, T. et al., 2023. Tackling malware and ransomware in gaming. Journal of Cyber Threat
						Analysis, 16(2), pp.93-107.
						<br /><br />
						Adil, M., Song, H. and Khan, M.K., 2024. 5G/6G-enabled metaverse technologies: Taxonomy,
						challenges, and research directions. Journal of Network and Computer Applications. [DOI:
						10.1016/j.jnca.2024.103828].
						<br /><br />
						Written by Stephen Gray
					</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_3() {
	return {
		date: "17/03/2025",
		title: "Evolving Threats, Evolving design.",
		description:
			"An informal personal perspective of the intersection of UX and modern day CyberSecurity.",
		keywords: [
			"User Experience",
			"Cybersecurity",
			"Cybersecurity UX",
			"UX",
			"UX and Cybersecurity",
			"Stephen",
			"Stephen Gray",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						Evolving Threats, Evolving Design
						<br />
						<br />
						Imagine a system that protect their users, without making their lives unnecessarily complicated… Imagine an app, that embraces compliance, without sacrificing Product-Market fit?  In today’s world, robust security protocols are essential. Overly complex measures often lead to frustration and non-compliance. In the words of Don Norman: "It is the designer's job to take complex requirements and make them so understandable and appropriate that they are pronounced simple” (jnd.org, 2019). When’s the last time two-factor authentication didn’t irritate you? Striking the balance between security, and usability is no longer optional… It’s crucial.
						For designers and developers, it’s the pinnacle of growth hacking, it's the missing piece for championing the colloquial ‘Category King’, through User Experience any developer can pin the nail on both compliance and scalability simultaneously.
						The problem?
						Cybersecurity is designed on a sole principle, technical robustness. Complex password requirements, multi-step authentication processes, frequent security prompts, it’s almost overwhelming listing these things let alone adopting them. In the experience of simply sitting down and writing this blog article I have had to:
						<br />
						-	Log into my PC using my own security pin
						<br />
						-	Log into Microsoft OneDrive using a password, and my phone for 2 Factor authentication
						<br />
						-	Log into Office 365 Web, again using a password and my phone for 2 Factor authentication
						<br />
						Have you ever had the experience of having lost a phone, or perhaps access to your authentication applications? Or changed a mobile number and unwittingly found yourself unmotivated after you’ve spoken to multiple support teams, and you can’t log into your own email system? Frustrating right? Research tells us that you are not alone (Albarrak, 2024).
						If there’s one thing you can be sure of, it’s that frustrated humans are resourceful creatures. Energy that could be directed towards the developer’s goal is swiftly set on averting such frustrating inconveniences. This is a dangerous space, the user is now writing down passwords, perhaps they’re reusing one complex password for all their accounts, they’re franticly navigating settings trying to find a way to disable 2FA, they’re setting up a whole range of 2FA devices, email addresses, phone numbers, perhaps accessible with the same passwords circumventing effectiveness entirely.
						Suddenly robust design isn’t so robust. Built upon the foundation of forcing a user into good security practice becomes the designers self-fulfilling prophecy… the door is open.
						<br />
						<br />
						The solution?
						<br />
						Research tells us that enhancing the user experience can increase adherence to security protocols (Wesam Fallatah, Furnell and He, 2023). The key is simplifying authentication processes! Leveraging technologies like biometrics, utilising hardware tokens and implementing password management is a step in the right direction but merely scratching the surface of what will be required. My own belief is that a concerted effort to bridge the gap between Cyber Security engineers, designers and developers would be a great catalyst for an evolution in cybersecurity effectiveness.
						Looking at the past is often a great way to plan for the future, and a shining light in the User Experience industry is Apple. In 2007 Apple brought a new product to the masses, the Apple iPhone. For the majority of customers, this product was the first touch screen device they had seen. On top of that the ‘smart’ features and ability to install 'apps’ left the general populace with a range of complex features at their fingertips. Apple’s User Experience design was so intuitive that most first time users naturally knew what they needed to do to achieve their intended goal. This symbiotic relationship between the complex development of advanced software’s and understanding the psychology and behaviour of the user base is commonly reflected upon today. How would I leverage UX to improve cyber security practices in systems and applications?
						<br />
						-	Educate through design. Users' actions should be a learning experience fit for the target user base, not a frustrating clunky, jargon filled source of stress! Apple paved the way for a user interface and experience that is still prevalent in the mobile industry today. How far off is your phones home screen to the original iOS 4x4 app grid?
						<br />
						-	Simplify authentication. Seamlessness' is the goal, what doesn’t the user need to see? What can be leveraged to lessen the users touch points, or shorten the user journey? Can it be automated? Can Single-Sign on technology be leveraged? My experience that I wrote about earlier in this post when describing ‘the problem’, as a user I signed on to a Microsoft PC and 2 Microsoft cloud services and they all needed authenticating individually. Frustrating!
						<br />
						-	Positive feedback loops. Capitalise on user psychiatry and reward users for security positive behaviours
						<br />
						-	Empowerment. Allow the users to feel empowered to decide their own security settings, the easiest way to persuade a person to take an action is to allow them to think it’s their own idea!
						So, where now?
						<br />
						Healthy and robust cybersecurity practices shouldn’t be a trade-off between protection and convenience. I don’t pretend to have all the puzzle pieces to answer the ever-evolving landscape of threats being faced today by users, but I think it can be agreed that the first steps are awareness, communication and open-discussion. One thing is for sure, discussion and unity between engineer and designer isn’t going to have an adverse effect. As Don Norman puts it in The Design of Everyday things “The problem with the designs of most engineers is that they are too logical. We have to accept human behaviour the way it is, not the way we would wish it to be.” (Norman, 2013)
						<br />
						<br />
						Bibliography
						<br />
						Abbas Moallem (2023) HCI for Cybersecurity, Privacy and Trust. Springer Nature.
						Albarrak, A.M. (2024) Integration of Cybersecurity, Usability, and Human-Computer Interaction for Securing Energy Management Systems. Sustainability [online]. 16 (18), p. 8144.
						Grobler, M., Gaire, R. and Nepal, S. (2021) User, Usage and Usability: Redefining Human Centric Cyber Security. Frontiers in Big Data [online]. 4.
						jnd.org (2019) jnd.org jnd.org. 26 January 2019 [online]. Available from: https://jnd.org/ [Accessed 15 February 2025].
						Norman, D. (2013) The Design of Everyday Things. Massachusetts, MIT Press.
						Wesam Fallatah, Furnell, S. and He, Y. (2023) Refining the Understanding of Usable Security. Lecture notes in computer science [online]. pp. 49–67. [Accessed 15 March 2025].
						<br />
						<br />
						Written by Stephen Gray
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2, article_3];

export default myArticles;
